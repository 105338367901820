.contact {
  margin: 2rem 0;
  a {
    display: block;
    font-weight: 700;
    font-size: 1rem;
    padding-top: 0.75rem;
    text-decoration: none;
    color: black;
  }
}
