body,
html {
  margin: 0;
  padding: 0;
}

.mainmenu-nav {
  display: flex;
  justify-content: space-between;
}

ul li {
  display: inline-block;
  font-weight: 400;
  padding: 0;
  font-size: 1rem;
}

.navigation {
  ul li a {
    font-weight: 600;
    text-decoration: none;
    color: black;
    padding-left: 0.5rem;
  }

  .logo ul {
    padding-inline-start: 0;
  }
  ul li {
    font-weight: bold;
  }

  a.active {
    border-bottom: 2px solid black;
  }
}
