.container {
  padding: 0 1.5rem;
  margin: 0 auto;
  max-width: 1100px;
}

.test {
  max-width: 100%;
}

.hide {
  visibility: hidden;
  display: none;
}

.show {
  visibility: visible;
  display: inline;
}

.wrapper {
  position: relative;
  height: 1000px;
}

.content {
  position: relative;
  z-index: 100;
  margin: 0 auto;
}

.emoji:hover {
  perspective: 1000px;
  cursor: default;
  animation: wave 1.25s 1 0s alternate;
  transform-origin: center bottom;
}

@keyframes wave {
  0% {
    transform: rotate(deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(deg);
  }
}

.werkje {
  width: 100%;
  margin: 1.5rem 0;
  img {
    box-shadow: 0px 0px 34px rgba(244, 244, 244, 0),
      0px 0px 13px rgba(245, 245, 245);
    // box-shadow: 0px 4px 16px rgba(17, 17, 26, 0.05), 0px 8px 32px #11111a0d;
  }
}

.portfolio-wrapper {
  margin: 2rem 0;
  padding-top: 3rem;
}

img {
  width: 100%;
}

.single-mockup {
  max-width: 30rem;
  max-height: 30rem;
}

@media screen and (min-width: 1000px) {
  .intro {
    max-width: 75%;
  }
  .description {
    max-width: 35rem;
  }
  .hide {
    visibility: visible;
    display: inline;
  }
  .show {
    visibility: hidden;
    display: none;
  }
}
