* {
  margin: 0;
  padding: 0;
}

.me-img {
  border-radius: 5%;
}

.grid-1 {
  max-width: 100%;
}

.main-menu {
  padding-top: 2rem;
}

.wrapper-home {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.illustratie img {
  max-width: 100%;
  border: 1rem solid white;
  box-shadow: 0px 4px 16px rgba(17, 17, 26, 0.05), 0px 8px 32px #11111a0d;
}

.illustratie p {
  display: flex;
  justify-content: space-evenly;
}

.grid-project {
  margin-top: 2rem;
}
.work {
  gap: 2rem;
}

.body-text {
  // max-width: none;
  padding-top: 0.75rem;
}

@media screen and (min-width: 1000px) {
  .grid-1 {
    display: grid;
    grid-template-columns: 70% 1fr;
    grid-gap: 4rem;
  }
  .wrapper-home {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .work {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  .grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
    margin-bottom: 3rem;
    grid-row-gap: 5rem;
  }

  .grid-3 {
    display: grid;
    grid-template-columns: 1fr 70%;
    grid-gap: 4rem;
    margin-bottom: 3rem;
    grid-row-gap: 5rem;
  }
  .grid-project {
    display: grid;
    grid-template-columns: 1fr 70%;
    grid-gap: 4rem;
    margin-bottom: 3rem;
    grid-row-gap: 5rem;
  }
  .body-text {
    max-width: 35rem;
    padding-top: 0;
  }
}
